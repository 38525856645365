import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "./LeafletMap.css";
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const LeafletMap = () => {
  const position = [33.3436, 74.3436];
  const lahoreLocation = [31.45104598863964, 74.45165648148432];

  return (
    <div className='reveal-from-top transition-from-top'>
      <MapContainer 
        center={position} 
        zoom={6} 
        style={{height: "40rem", width: "100%"}}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        
        <Marker position={lahoreLocation}>
          <Popup>
            <p style={{color: "black", fontSize: "1.2rem"}}><strong>Lahore, Punjab, Pakistan (Main Office)</strong> <br /> Main Boulevard, DHA Phase 9 Town, Workshop Stop, Bedian Road</p>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
