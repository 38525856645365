import "./NotFound.css";

import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate("/404");
  }, []);

  return (
    <section className="not-found container">
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>Looks like you have wandered off!</p>
      <Link to="/">
        <button>Return To Home</button>
      </Link>
    </section>
  );
}
 
export default NotFound;