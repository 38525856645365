import "./TextCard.css";

const TextCard = ({title, description, animation = ""}) => {
  return (
    <section className={`text-card ${animation}`}>
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
    </section>
  );
}
 
export default TextCard;