import "./ProductsList.css";

import ProductCard from "../../../General/ProductCard/ProductCard";

const ProductsList = ({ products, filter, setSelectedProduct, animation = "" }) => {
  let filteredProducts = products;

  if(filter)
    filteredProducts = products.filter(product => product.name.toLowerCase().includes(filter.trim().toLowerCase()));

  return (
    <section className={`products-list ${animation}`}>
      {
        filteredProducts.
        length?
        filteredProducts.map((product, index) => (
          <div onClick={() => setSelectedProduct(product)} key={index}>
            <ProductCard 
              key={index}
              image={product.image}
              name={product.name}
              description={product.shortDescription}
            />
          </div>
      ))
      :
      <h4 style={{textAlign: "center"}}>No Service Found!</h4>
      }
    </section>
  );
}
 
export default ProductsList;