import { useRef, useState } from "react";

import "./ProductModal.css";

const ProductModal = ({ product, setSelectedProduct }) => {
  const tabsRef = useRef(null);
  const [contentType, setContentType] = useState("description");

  const handleTabChange = (className) => {
    const highlight = tabsRef.current.children[0];
    const description = tabsRef.current.children[1];
    const keyOfferings = tabsRef.current.children[2];

    if
    (className === "description"
      &&
      !description.classList.contains("selected-tab")  
    ) {
      description.classList.add("selected-tab");
      keyOfferings.classList.remove("selected-tab");
      highlight.classList.remove("highlight-right");
      highlight.classList.add("highlight-left");
      setContentType("description");
    }

    if
    (className === "key-offerings"
      &&
      !keyOfferings.classList.contains("selected-tab")  
    ) {
      keyOfferings.classList.add("selected-tab");
      description.classList.remove("selected-tab");
      highlight.classList.remove("highlight-left");
      highlight.classList.add("highlight-right");
      setContentType("key-offerings");
    }
  }

  return (
    <>
      <div className="modal-overlay"></div>
      <section className="product-modal">
        <p 
          className="close"
          onClick={() => setSelectedProduct(null)}
        >
          x
        </p>
        <div className="product-image">
          <img src={product.image} alt="Product-Image" />
        </div>
        <h3>{product.name}</h3>
        <div className="tabs" ref={tabsRef}>
          <div className="highlight highlight-left"></div>
          <h4 className="description selected-tab" onClick={() => handleTabChange("description")}>Description</h4>
          <h4 className="key-offerings" onClick={() => handleTabChange("key-offerings")}>Key Offerings</h4>
        </div>
        {
          contentType === "description"
          &&
          <p>{product.longDescription}</p>
        }
        {
          contentType === "key-offerings"
          &&
          <ul style={{listStyle: "none"}}>
          {
            product.keyOfferings?.map((keyOffering, index) => 
              <li key={index}><span style={{color: "var(--secondary)"}}>✔</span> {keyOffering}</li>
            )
          }
          </ul>
        }
      </section>
    </>
  );
}
 
export default ProductModal;