import "./CEOMessage.css";

import ceoImg from "../../Images/AboutUs/ceo-img.png";
import quoteIcon from "../../Images/General/quote-icon.svg";

const CEOMessage = () => {
  return (
    <section className="ceo-message">
      <div className="container">
        <div className="left-section">
          <h6 className="reveal-from-top transition-from-top">A Word from Our Leader</h6>
          <h1 className="reveal-from-top transition-from-top">CEO's Message</h1>
          <div className="quote-icon">
            <img src={quoteIcon} className="reveal-from-right transition-from-top" alt="Quote-Icon" />
          </div>
          <p className="reveal-from-top transition-from-top"><i>"At Fire Flick Solutions, our mission is to provide top-tier fire and safety equipment that surpasses industry standards. As CEO, I ensure that our products offer exceptional reliability and peace of mind, thanks to our dedicated team and trusted partners."</i></p>
          <p className="reveal-from-top transition-from-top"><i>"Our commitment extends beyond just supplying equipment; we support you throughout with expertise and responsiveness to meet your needs. Thank you for choosing Fire Flick Solutions—together, we are dedicated to enhancing safety and security."</i></p>
          <p className="reveal-from-top transition-from-top"><i><strong>&#9679; Muhammad Nadeem, CEO of Fire Flick Solutions</strong></i></p>
          <div className="quote-icon-alternate">
            <img src={quoteIcon} className="reveal-from-top transition-from-top" alt="Quote-Icon" />
          </div>
        </div>
        <div className="right-section">
          <img src={ceoImg} className="reveal-from-left transition-from-left" alt="CEO-Img" />
        </div>
      </div>
    </section>
  );
}
 
export default CEOMessage;