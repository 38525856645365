import GeneralHero from "../General/GeneralHero/GeneralHero";
import BrandMessage from "./BrandMessage/BrandMessage";
import CEOMessage from "./CEOMessage/CEOMessage";
import OurMission from "./OurMission/OurMission";
import CoreValues from "./CoreValues/CoreValues";

const AboutUs = () => {
  return (  
    <main style={{paddingTop: "8rem"}}>
      <GeneralHero 
        title={"About Us"} 
        description={"Fire Flick Solutions delivers top-tier safety equipment and solutions, ensuring protection and reliability across all sectors."}
      />
      <BrandMessage />
      <CEOMessage />
      <OurMission />
      <CoreValues />
    </main>
  );
}
 
export default AboutUs;