import "./Products.css";

import ProductsList from "./ProductsList/ProductsList";
import DataListInput from "../../General/DataListInput/DataListInput";

import { products } from "../../Data/products";

import { useState } from "react";

const Products = ({ setSelectedProduct }) => {
  const [filter, setFilter] = useState(null);

  return (
    <section className="products container">
      <h6 className="transition-from-bottom">Offerings You Can Rely On</h6>
      <h1 className="transition-from-left">What We Offer</h1>
      <DataListInput 
        animation={"transition-from-top"}
        options={products.map(product => product.name)} 
        listName={"services"} 
        placeholder={"Enter Service Name"} 
        setFilter={setFilter}
      />
      <ProductsList 
        animation={"transition-from-top"}
        products={products} 
        setSelectedProduct={setSelectedProduct} 
        filter={filter} />
    </section>
  );
}
 
export default Products;