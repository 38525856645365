import React, { useEffect, useState, useRef } from 'react';
import './Circle.css';

const Circle = ({ title, description, percentage, size = 180, strokeWidth = 5 }) => {
  const [progress, setProgress] = useState(0);
  const circleRef = useRef(null);

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setProgress(percentage);
          }
          else {
            setProgress(0);
          }
        });
      },
    );

    if (circleRef.current) {
      observer.observe(circleRef.current);
    }

    return () => {
      if (circleRef.current) {
        observer.unobserve(circleRef.current);
      }
    };
  }, [percentage]);

  return (
    <section className="circle" ref={circleRef}>
      <div className="progress-wrapper" style={{ width: size, height: size }}>
        <svg width={size} height={size}>
          {/* Background Circle */}
          <circle
            className="progress-bg"
            stroke="#e6e6e6"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth - 1}
            fill="none"
          />
          {/* Progress Circle */}
          <circle
            className="progress-bar"
            stroke="#f12602"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={strokeWidth}
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={circumference}
            style={{
              strokeDashoffset: offset,
              transition: 'stroke-dashoffset 1.5s ease-in',
              transitionDelay: '0.25s',
            }}
          />
        </svg>
        <h3 className="progress-text">{progress}%</h3>
      </div>
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
    </section>
  );
};

export default Circle;
