import { useEffect, useRef, useState } from "react";

import "./Carousel.css";

const Carousel = ({ contents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null);
  const controlsRef = useRef(null);
  const intervalRef = useRef(null);

  const startInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setCurrentIndex(prevVal => ((prevVal + 1) % contents.length));
    }, 4000);
  };

  useEffect(() => {
    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [contents.length]);

  useEffect(() => {
    if (controlsRef.current) {
      if (prevIndex !== null && prevIndex !== currentIndex) {
        const previousDot = controlsRef.current.children[prevIndex];
        if (previousDot) {
          previousDot.classList.remove("highlighted-dot");
        }
      }

      const currentDot = controlsRef.current.children[currentIndex];
      if (currentDot) {
        currentDot.classList.add("highlighted-dot");
      }

      setPrevIndex(currentIndex);
    }
  }, [currentIndex]);

  return (
    <section className="carousel reveal-from-top">
      <div className="content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {contents.map((content, index) => (
          <div key={index} className="slide">
            <h2>{content?.title}</h2>
            <p>{content?.description}</p>
          </div>
        ))}
      </div>
      <div className="controls" ref={controlsRef}>
        {contents.map((_, index) => (
          <span
            className={`dot ${index === currentIndex ? "highlighted-dot" : ""}`}
            key={index}
            onClick={() => {
              setCurrentIndex(index);
              startInterval();
            }}
          >
          </span>
        ))}
      </div>
    </section>
  );
}

export default Carousel;
