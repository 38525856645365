import GeneralHero from "../General/GeneralHero/GeneralHero";
import InfoSection from "./InfoSection/InfoSection";
import LocationsSection from "./LocationsSection/LocationsSection";

const ContactUs = () => {
  return (
    <main style={{paddingTop: "8rem"}}>
      <GeneralHero 
        title={"Contact Us"} 
        description={"Reach out today for inquiries, support, or personalized fire safety solutions."}   
      />
      <InfoSection />
      <LocationsSection />
    </main>
  );
}
 
export default ContactUs;