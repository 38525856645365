import "./DataListInput.css";

const DataListInput = ({ listName, options, placeholder, setFilter, animation }) => {
  return (
    <div className={`data-list-input ${animation}`}>
      <input 
        list={listName} 
        placeholder={placeholder} 
        onChange={(e) => {
          setFilter(e.target.value);
        }} 

      />
      <datalist id={listName}>
        {
          options.map((option, index) => <option value={option} key={index}>{option}</option>)
        }
      </datalist>
    </div>
  );
}
 
export default DataListInput;