import "./Statistics.css";

import Circle from "./Circle/Circle";

const Statistics = () => {
  return (
    <section className="statistics">
      <div className="container">
        <h4 className="reveal-from-top">Dedication in action</h4>
        <h1 className="reveal-from-top">Your Safety, Our Priority</h1>
        <p className="reveal-from-top">Our commitment to safety and durability means you can count on our equipment when it matters most.</p>
        <div className="circles reveal-from-top">
          <Circle title="Performance" description="Unmatched performance under pressure." percentage={98} />
          <Circle title="Reliability" description="Flawless in critical situations." percentage={96} />
          <Circle title="Customer Trust" description="Trusted by customers worldwide." percentage={99} />
          <Circle title="Protection" description="Utmost protection in danger." percentage={100} />
        </div>
      </div>
    </section>
  );
}
 
export default Statistics;