import "./header.css";

import logo from "../../Images/General/logo-white.png";
import hamburgerIcon from "../../Images/General/hamburger-icon.svg";

import { Link } from "react-router-dom";
import { useRef } from "react";

const Header = () => {
  const bottomSectionRef = useRef(null);

  const toggleBottomSection = (iconStyle) => {
    const bottomSection = bottomSectionRef.current;

    const computedStyles = window.getComputedStyle(bottomSection);

    if (computedStyles.visibility === "hidden") {
      bottomSection.style.visibility = "visible";
      bottomSection.style.maxHeight = "140px";
      bottomSection.style.opacity = "1";
    } else {
      bottomSection.style.maxHeight = "0px";
      bottomSection.style.visibility = "hidden";
      bottomSection.style.opacity = "0";
    }

    if(iconStyle.rotate === "90deg") {
      iconStyle.rotate = "0deg";
    }
    else {
      iconStyle.rotate = "90deg";
    }
  }

  return (
    <header>
      <div className="container">
        <div className="left-section">
          <Link to="/">
            <img src={logo} alt="Fire-Flick-Logo" />
          </Link>
        </div>
        <div className="right-section">
          <div className="lg">
            <Link to="/">Home</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/services">Key Offerings</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
          <div className="sm" onClick={(e) => toggleBottomSection(e.currentTarget.style)}>
            <img src={hamburgerIcon} alt="Hamburger-Icon" />
          </div>
        </div>
      </div>
      <div className="bottom-section" ref={bottomSectionRef}>
          <Link to="/">Home</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/services">Services</Link>
          <Link to="/contact-us">Contact Us</Link>
      </div>
    </header>
  );
}
 
export default Header;