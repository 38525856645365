import "./CoreValues.css";

import TextCard from "../../General/TextCard/TextCard";

const CoreValues = () => {
  return (
    <section className="core-values">
      <div className="container">
        <h6 className="reveal-from-top">What Drives Us</h6>
        <h1 className="reveal-from-top">Core Values</h1>
        <div className="cards">
          <TextCard title={"Safety"} description={"Prioritizing the protection of lives and property above all else."} animation={"reveal-from-left"} />
          <TextCard title={"Integrity"} description={"Conducting business with honesty, transparency, and ethical standards."} animation={"reveal-from-right"} />
          <TextCard title={"Innovation"} description={"Embracing creativity and technology to advance safety solutions."} animation={"reveal-from-left"} />
          <TextCard title={"Customer Focus"} description={"Committing to understanding and fulfilling our customers' needs."} animation={"reveal-from-right"} />
        </div>
      </div>
    </section>
  );
}
 
export default CoreValues;