import { Routes, Route } from "react-router-dom";

import "./General/Styles/general.css";

import Header from "./General/Header/Header";
import Footer from "./General/Footer/Footer";
import NotFound from "./General/NotFound/NotFound";

import Home from "./Home/Home";
import AboutUs from "./AboutUs/AboutUs";
import Services from "./Services/Services";
import ContactUs from "./ContactUs/ContactUs";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== "/404" && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname !== "/404" && <Footer />}
    </>
  );
}

export default App;
