import "./Introduction.css";

import introImg1 from "../../Images/Home/Introduction/intro-img-1.jpg";
import introImg2 from "../../Images/Home/Introduction/intro-img-2.jpg";
import introIcon1 from "../../Images/Home/Introduction/intro-icon-1.svg";
import introIcon2 from "../../Images/Home/Introduction/intro-icon-2.svg";

const Introduction = () => {
  return (
    <section className="introduction container">
      <div className="left-section">
        <img src={introImg1} className="reveal-from-left" alt="Intro-Img-1" />
        <img src={introImg2} className="reveal-from-left" alt="Intro-Img-2" />
      </div>
      <div className="right-section">
        <h6 className="reveal-from-top">Reliable Equipment for Every Emergency</h6>
        <h1 className="reveal-from-top">Equip yourself with the best</h1>
        <p className="reveal-from-top">At Fire Flick Solutions, we are committed to delivering exceptional firefighting equipment that supports your mission every step of the way.</p>
        <div className="bottom-section">
          <div className="mission">
            <div className="left-section">
              <img src={introIcon1} className="reveal-from-top" alt="Intro-Icon-1" />
            </div>
            <div className="right-section reveal-from-left">
              <h4>Mission</h4>
              <p>To provide top-quality gear that empowers firefighters, building lasting relationships.</p>
            </div>
          </div>
          <div className="vision">
            <div className="left-section">
              <img src={introIcon2} className="reveal-from-top" alt="Intro-Icon-2" />
            </div>
            <div className="right-section reveal-from-left">
              <h4>Vision</h4>
              <p>To lead in innovation and quality, enhancing firefighter safety and effectiveness with every product.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
 
export default Introduction;