import "./InfoSection.css";

import phoneIcon from "../../Images/General/phone-icon.svg";
import emailIcon from "../../Images/General/email-icon.svg";
import webIcon from "../../Images/General/web-icon.svg";
import locationIcon from "../../Images/General/location-icon.svg";
import facebookIcon from "../../Images/General/facebook-icon.svg";
import xIcon from "../../Images/General/x-icon.svg";
import youtubeIcon from "../../Images/General/youtube-icon.svg";

import { Link } from "react-router-dom";

const InfoSection = () => {
  return (
    <section className="info-section container">
      <h6 className="transition-from-bottom">We're Here for You</h6>
      <h1 className="transition-from-left">Always Ready To Help</h1>
      <p className="transition-from-top">Feel free to get in touch for expert support.</p>
      <div className="icons-grid transition-from-top">
        <div className="phone">
          <div className="icon">
            <img src={phoneIcon} alt="Phone-Icon" />
          </div>
          <div className="info">
            <h4>Phone Number</h4>
            <Link to="tel:+923454877525">
              0345-4877525
            </Link>
            <span style={{ color: "white"}}> / </span>
            <Link to="tel:+923074875525">
              0307-4875525
            </Link>
          </div>
        </div>
        <div className="email">
          <div className="icon">
            <img src={emailIcon} alt="Email-Icon" />
          </div>
          <div className="info">
            <h4>Email</h4>
            <Link to="mailto:info@fireflicksolutions.com">
              info@fireflicksolutions.com
            </Link>
          </div>
        </div>
        <div className="website">
          <div className="icon">
            <img src={webIcon} alt="Website-Icon" />
          </div>
          <div className="info">
            <h4>Website</h4>
            <Link to="/">
              fireflicksolutions.com
            </Link>
          </div>
        </div>
        <div className="location">
          <div className="icon">
            <img src={locationIcon} alt="Location-Icon" />
          </div>
          <div className="info">
            <h4>Location</h4>
            <Link
              to="https://www.google.com/maps?q=31.45104598863964,74.45165648148432"
              target="_blank"
            >
              Lahore (Head Office)
            </Link>
            <span>, </span>
            <Link
              to="https://www.google.com/maps?q=33.627761975387216, 73.12029454028006"
              target="_blank"
            >
              Islamabad
            </Link>
            <span>, </span>
            <Link
              to="https://www.google.com/maps?q=34.00195715996371, 71.54533165304267"
              target="_blank"  
            >
              KPK
            </Link>
          </div>
        </div>
      </div>
      <div className="socials transition-from-top">
        <h4>Follow Us</h4>
        <div className="icons">
          <Link 
            to="https://www.facebook.com/profile.php?id=61561907176898&mibextid=kFxxJD"
            target="_blank"
          >
            <img src={facebookIcon} alt="Facebook-Icon" />
          </Link>
          <Link 
            to="https://x.com/m_nadeem88?s=08"
            target="_blank"
          >
            <img src={xIcon} alt="X-Icon" />
          </Link>
          <Link 
            to="https://youtube.com/@m.nadeem1988?feature=shared"
            target="_blank"
          >
            <img src={youtubeIcon} alt="Youtube-Icon" />
          </Link>
        </div>
      </div>
    </section>
  );
}
 
export default InfoSection;