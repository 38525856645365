import "./ProductCard.css";

const ProductCard = ({image, name, description}) => {
  return (
    <section className="product-card">
      <div className="product-image">
        <img src={image} alt="" />
      </div>
      <div className="product-info">
        <h4 className="product-name">{name}</h4>
        <p className="product-description">{description}</p>
      </div>
      <h6 className="see-more">See More...</h6>
    </section>
  );
}
 
export default ProductCard;