import "./GeneralHero.css";

const GeneralHero = ({ title, description }) => {
  return (
    <section className="general-hero">
      <div className="container">
        <h1 className="transition-from-bottom">{ title }</h1>
        <p className="transition-from-top">{ description }</p>
      </div>
    </section>
  );
}
 
export default GeneralHero;