import Hero from "./Hero/Hero";
import Introduction from "./Introduction/Introduction";
import Statistics from "./Statistics/Statistics";
import CTA from "./CTA/CTA";

const Home = () => {
  return (
    <main style={{paddingTop: "8rem", overflowX: "hidden"}}>
      <Hero />
      <Introduction />
      <Statistics />
      <CTA />
    </main>
  );
}
 
export default Home;