import "./OurMission.css";

import Carousel from "../../General/Carousel/Carousel";

const missionStatements = [
  {
    title: "Value",
    description: "Deliver services and products of higher value to customers."
  },
  {
    title: "Reliability",
    description: "Be a reliable provider of firework and support services in the area."
  },
  {
    title: "Partnerships",
    description: "Build firm partnerships with our customers and suppliers that benefit both parties."
  },
  {
    title: "Expansion",
    description: "Expand into local and regional markets, delivering value to all stakeholders."
  },
  {
    title: "Growth",
    description: "Develop our capabilities by employing skilled workforce and adding state-of-the-art equipment."
  }
];

const OurMission = () => {
  return (
    <section className="our-mission container">
      <h6 className="reveal-from-top">Driven by Purpose</h6>
      <h1 className="reveal-from-top">Our Mission</h1>
      <Carousel contents={missionStatements} />
    </section>
  );
}
 
export default OurMission;