import { useEffect, useState } from "react";

import GeneralHero from "../General/GeneralHero/GeneralHero";
import Products from "./Products/Products";
import ProductModal from "./ProductModal/ProductModal";

const Services = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if(selectedProduct){
      document.body.classList.add("prevent-scroll");
    }
    else{
      document.body.classList.remove("prevent-scroll");
    }
  }, [selectedProduct]);

  return (
    <main style={{paddingTop: "8rem"}}>
      <GeneralHero title={"Key Offerings"} description={"Delivering reliable fire safety services that protect lives and property."} />
      <Products setSelectedProduct={setSelectedProduct} />
      {
        selectedProduct &&
        <ProductModal product={selectedProduct} setSelectedProduct={setSelectedProduct} />
      }
    </main>
  );
}
 
export default Services;