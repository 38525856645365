import "./CTA.css";

import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <section className="cta">
      <div className="container">
        <h1 className="reveal-from-top">Like What You See?</h1>
        <p className="reveal-from-top">Explore our full range of services designed to meet your needs.</p>
        <Link to="/services">
          <button className="reveal-from-top">Discover Our Services</button>
        </Link>
      </div>
    </section>
  );
}
 
export default CTA;