import "./BrandMessage.css";

import fireFlickLogo from "../../Images/General/fire-flick-logo.png";

const BrandMessage = () => {
  return (
    <section className="brand-message container">
      <div className="left-section">
        <h6 className="transition-from-top">Brand Message</h6>
        <h1 className="transition-from-top">Who We Are</h1>
        <p className="transition-from-top">Fire Flick Solutions is a leading provider of high-quality safety equipment and solutions dedicated to protecting lives and property. we are quickly building a solid reputation for reliability, innovation, and excellence in the fire safety industry. Our extensive range of products includes fire extinguishers, alarms, protective gear, and emergency response equipment, catering to diverse sectors including commercial, industrial, residential, and governmental clients.</p>
        <p className="transition-from-top">Driven by a commitment to safety and customer satisfaction, Fire Flick Solutions takes pride in delivering superior products sourced from reputable manufacturers worldwide. Our team of experienced professionals ensures that every product meets rigorous quality standards and regulatory requirements, offering peace of mind to our valued customers.</p>
      </div>
      <div className="right-section">
        <img src={fireFlickLogo} className="transition-from-right" alt="fire-flick-logo" />
      </div>
    </section>
  );
}
 
export default BrandMessage;