import "./LocationsSection.css";

import LeafletMap from "../../General/LeafletMap/LeafletMap";

const LocationsSection = () => {
  return (
    <section className="locations-section">
      <div className="container">
        <h6 className="reveal-from-top transition-from-bottom">Come Visit Us</h6>
        <h1 className="reveal-from-top transition-from-left">Our Locations</h1>
        <LeafletMap />
      </div>
    </section>
  );
}
 
export default LocationsSection;